/* global window */
import {
    applyMiddleware,
    compose,
    createStore,
} from 'redux';
import {
    persistReducer,
    persistStore,
    createTransform,
} from 'redux-persist';
import {
    createEpicMiddleware,
} from 'redux-observable';

import storage from 'redux-persist/lib/storage'
import Reactotron from './ReactotronConfig'
import reducers from './reducers';
import epics from '../api/epics';

export default (initialState) => {
    // Redux Persist config
    // const initialCommon = {
    // }
    const initialPasscode = {
        isLoading: false,
    }
    const initialUnlock = {
        currentScene: 'meetScene',
        chance: 5,
        filped: []
    }

    const config = {
        key: 'root',
        storage: storage,
        transforms: [
            createTransform(
                (state) => state,
                (state) => ({ ...state, }),
                { whitelist: ['common'] },
            ),
            createTransform(
                (state) => state,
                (state) => ({ ...state, ...initialPasscode }),
                { whitelist: ['passcode'] },
            ),
            createTransform(
                (state) => state,
                (state) => ({ ...state, ...initialUnlock }),
                { whitelist: ['unlock'] },
            ),
        ]
    }

    const reducer = persistReducer(config, reducers);

    const epicMiddleware = createEpicMiddleware();

    const middlewares = [epicMiddleware];

    const composeEnhancers = (
        window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ) || compose;

    const enhancer = composeEnhancers(
        applyMiddleware(...middlewares),
        Reactotron.createEnhancer()
    );

    const store = createStore(
        reducer,
        initialState,
        enhancer,
    );

    epicMiddleware.run(epics);

    const persistor = persistStore(
        store,
        undefined,
    );

    return {
        persistor,
        store,
    };
}