import React from "react";
import Helmet from "react-helmet";
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../utils/commonStyles";
import { lightTheme, darkTheme } from "../components/Themes";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import HomeScreen from './HomeScreen';
import NotFoundScreen from './NotFoundScreen';
import * as CommonActions from '../redux/actions/CommonActions';
import { isNotNull } from '../utils/commonFunction';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <ThemeProvider theme={this.props.theme == 'dark' ?darkTheme :lightTheme}>
        <>
          <GlobalStyles />
          <Router>
            <Helmet>
              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="../images/favicon/apple-touch-icon.png?1"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="../images/favicon/favicon-32x32.png?1"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="../images/favicon/favicon-16x16.png?1"
              />
              <link rel="manifest" href="../images/favicon/site.webmanifest" />
              <meta name="msapplication-TileColor" content="##ffffff" />
              <meta name="theme-color" content="#ffffff" />
              <title>GiftYou</title>
            </Helmet>
            <div className="body">
              <Switch>
                <Route exact path="/" component={HomeScreen} />
                <Route component={NotFoundScreen} />
              </Switch>
            </div>
          </Router>
          <ToastContainer />
        </>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = state => ({
  theme: state.common.theme,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);