// initialize
export const INITIAL_STATE = "@@common/INITIAL_STATE";

// Change Theme
export const CHANGE_THEME = '@@common/CHANGE_THEME';

// Display toast
export const DISPLAY_TOAST_MESSAGE = '@@common/DISPLAY_TOAST_MESSAGE';

// Template 
export const TEMPLATE = '@@type/TEMPLATE';
export const TEMPLATE_FULFILLED = '@@type/TEMPLATE_FULFILLED';
export const TEMPLATE_REJECTED = '@@type/TEMPLATE_REJECTED';
export const TEMPLATE_CANCELED = '@@type/TEMPLATE_CANCELED';