import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight, faStar, faEllipsisV, faRedoAlt, faCheck, faBars } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight'
import FadeIn from 'react-fade-in';
import {Stickyroll} from '@stickyroll/stickyroll';
import { push as Menu } from 'react-burger-menu'
import Typist from 'react-typist';

import '../css/HomeScreen.css';
import heart from '../images/heart.png'
import logo from '../images/logo_long.png'
import phone from '../images/phone-bg.png'
import daily_schedule from '../images/features/daily-schedule.png'
import date_invitations from '../images/features/date-invitations.png'
import gift_game from '../images/features/gift-game.png'
import important_speech_to_you from '../images/features/important-speech-to-you.png'
import a_boy from '../images/gif/boy.png';
import a_girl from '../images/gif/girl.png';
import memorial_gallery from '../images/features/memorial-gallery.png'
import date_invitation_down from '../images/date-invitation-down.png'
import date_invitation_up from '../images/date-invitation-up.png'
import demo_back from '../images/demo/back.png'
import demo_chat from '../images/demo/chat.png'
import demo_gift_card from '../images/demo/gift-card.png'
import demo_gift_question from '../images/demo/gift-question.png'
import demo_letter from '../images/demo/letter.png'
import demo_main from '../images/demo/main.png'
import demo_memory from '../images/demo/memory.png'
import demo_schedule from '../images/demo/schedule.png'
import blender_letter from '../images/blender/letter.png'
import blender_gift from '../images/blender/gift.png'

// import * as CommonActions from '../redux/actions/CommonActions';
// import Loading from '../components/Loading';
import LottiePlayer from '../components/Lottie';
import { disableScroll, enableScroll, getWindowSize } from '../utils/commonFunction';
import SyncAPI from "../api/syncAPI"

const sections = {
  "web": [
    "main", "features_date_invitations", "features_gift_game", "features_memorial_gallery", "features_important_speech_to_you", "features_daily_schedule", "price_plan"
  ],
  "mobile": [
    "main", "features_date_invitations", "features_gift_game", "features_memorial_gallery", "features_important_speech_to_you", "features_daily_schedule", "price_plan", "price_plan_gift"
  ]
} 

function ScrollToTop({ page, progress }) {
  const [innerPage, setInnerPage] = useState(null);
  const [innerProgress, setInnerProgress] = useState(null);
  const [scrollTimeout, setScrollTimeout] = useState(null)

  useEffect(() => {
    if(page > 1 && innerProgress > progress && progress < 0.1) {
      disableScroll(1);
      window.location.replace('/#'+page)
      window.history.pushState({}, document.title, "/")
      clearTimeout(scrollTimeout)
      let tempTimeout = setTimeout(() => {
        enableScroll(1000);
      }, 1000)
      setScrollTimeout(tempTimeout)
    } 
    if(page > 1) {
      setInnerProgress(progress)
    }
  }, [progress])

  useEffect(() => {
    if(innerPage < page && page > 1) {
      disableScroll(2);
      clearTimeout(scrollTimeout)
      let tempTimeout = setTimeout(() => {
        enableScroll(1000);
      }, 1000)
      setScrollTimeout(tempTimeout)
    }
    if(innerPage !== page) {
      setInnerPage(page)
    }
  }, [page])

  return <></>
}

// ----------------------- Web -----------------------
function BoxHeader() {
  const { t } = useTranslation()
  return (
    <div className="box-body-header">
      <a className="box-logo-a" href="/"><img src={logo} className="box-logo"/></a>
               
      <div className="box-body-header-btn">
        <div className="nav-btn">
          <a href="#2">{t('home.nav_features')}</a>
        </div>
        <div className="nav-btn">
          <a href="#7">{t('home.nav_price_plan')}</a>
        </div>
        <div className="nav-btn nav-sign-btn noselect">
          {t('home.nav_sign_in')}
        </div>
      </div>
    </div>
  )
}

function MainPage({ toggleMakeItNow, page, progress }) {
  const { t } = useTranslation()

  return (
    <div className="main-div" style={page === 1 ?{'--progress': progress} :{display: "none"}}>
      <FadeIn>
        <h1 className="main-div-title fade-out-1">{t('home.main_titleline')} <b>{t('home.main_titleline_1')}</b> {t('home.main_titleline_2')} <b>{t('home.main_titleline_3')}</b> ?</h1>
      </FadeIn>
      <FadeIn delay={300}>
        <h2 className="main-div-content fade-out-2">
          {t('home.main_content')}
        </h2>
      </FadeIn>
      <FadeIn delay={600}>
        <div className={"main-action-btn noselect fade-out-3 " + ((progress > 0.01) ?"main-action-btn-disabled-hover" :"")} onClick={toggleMakeItNow} style={progress > 0.01 ?{transition: '0s'} :{}}>
          {t('home.main_make_it_now')}
        </div>
      </FadeIn>
      <div className="main-scroll-down-div noselect fade-out-4">
        <div className="main-scroll-down">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
          <small className="scroll-ps">{t('home.main_scroll_me')}</small>
        </div>
      </div>
    </div>
  )
}

function Background({ startLottieIndex, endLottieIndex }) {
  const [animationData, setAnimationData] = useState();
  
  useEffect(() => {
    import('../images/lottie/congratulation.json').then(setAnimationData)
  }, [])

  if (!animationData) return <div></div>
  return  <Lottie animationData={animationData} 
            play
            loop={0}
            speed={1}
            segments={[startLottieIndex, endLottieIndex]}
            onComplete={() => {
              if(document.body.style.overflowY === 'hidden') {
                document.body.style.overflowY = "scroll"
              }
            }}
          />
}

function Features({ initBackgroundAnimation, nextBackgroundAnimation, page, progress }) {
  const [innerPage, setInnerPage] = useState(null);
  const { t } = useTranslation()

  useEffect(() => {
    if(page >= 2) {
      nextBackgroundAnimation();
    } else if(innerPage === 2 && page === 1) {
      initBackgroundAnimation();
    }
    if(innerPage !== page) {
      setInnerPage(page)
    }
  }, [page])

  if(page >= 2 && page < 7) {
    return (
      <div className="feature-outer-div" style={{"--progress": progress}}>
        <div className="feature-left-div">
          <FadeIn>
            <div className="feature-phone-div">
              <img src={phone} className="feature-phone-img" alt=""/>
              <FeaturesDemo page={page} progress={progress} />
            </div>
          </FadeIn>
        </div>
        <div className="feature-right-div">
          <div>
            <h1 className="feature-right-div-title">{t('home.feature_feature')}</h1>
            <FeaturesContent page={page} />
          </div>

          <FadeIn>
          <div className="feature-action-button-div">
            {/* <div className="feature-demo-btn noselect">
              {t("home.feature_demo")}
            </div> */}
            <div className="feature-action-btn noselect">
              {t("home.feature_try_it_now")}
            </div>
          </div>
          </FadeIn>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

function FeaturesDemo({page, progress}) {
  {/* Date Invitation */}
  if(page === 2) {
    return (
      <>
      <div className="feature-2-img-div">
        <FadeIn delay={600}>
        <img src={date_invitation_up} className="feature-2-img-up feature-phone-up-fade-out" alt=""/>
        </FadeIn>
        <FadeIn delay={800}>
        <img src={date_invitation_down} className="feature-2-img-down feature-phone-down-fade-out" alt=""/>
        </FadeIn>
      </div>
      {
        progress > 0.51 && 
        <div className="feature-2-img-main-div">
          <img src={demo_main} className="feature-2-img-up  feature-main-fade" alt=""/>
          <img src={demo_chat} className="feature-2-img-down feature-main-chat-fade" alt=""/>
        </div>
      }
      </>
    )
  } else if(page === 3) {
    return (
      <>
      <div className="feature-2-img-div"  style={{"--progress": progress}}>
        {
          (progress < 1) &&
          <>
          <img src={demo_back} className="feature-3-img-back feature-main-fade feature-3-fade-out-2-up" alt=""/>
          <br/>
          </>
        }
        {
          progress < 0.5 &&
          <img src={demo_gift_card} className="feature-3-img-content feature-3-img-content-margin feature-main-chat-fade feature-3-fade-out-1" alt=""/>
        }
        {
          (progress > 0.5 && progress < 0.99) &&
          <img src={demo_gift_question} className="feature-3-img-content feature-3-img-content-margin feature-main-chat-fade feature-3-fade-out-2" alt=""/>
        }
      </div>
      </>
    )
  } else if(page === 4) {
    return (
      <div className="feature-2-img-div">
        <img src={demo_back} className="feature-3-img-back feature-main-fade feature-3-fade-out-2-up" alt=""/>
        <br/>
        {
          progress < 0.95 &&
          <img src={demo_memory} className="feature-4-img-content feature-main-chat-fade feature-3-fade-out-2" alt=""/>
        }
      </div>
    )
  } else if(page === 5) {
    return (
      <div className="feature-2-img-div">
        <img src={demo_back} className="feature-3-img-back feature-main-fade feature-3-fade-out-2-up" alt=""/>
        <br/>
        {
          progress < 0.95 &&
          <img src={demo_letter} className="feature-5-img-content feature-main-chat-fade feature-3-fade-out-2" alt=""/>
        }
      </div>
    )
  } else if(page === 6) {
    return (
      <div className="feature-2-img-div">
        <img src={demo_back} className="feature-3-img-back feature-main-fade feature-3-fade-out-2-up" alt=""/>
        <br/>
        {
          progress < 0.95 &&
          <img src={demo_schedule} className="feature-3-img-content feature-main-chat-fade feature-3-fade-out-2" alt=""/>
        }
      </div>
    )
  } else {
    return <></>
  }
}

function FeaturesContent({page}) {
  const { t } = useTranslation()

  if(page === 2) {
    return (
      <div className="feature-2-content-outer fade-out-3">
        <FadeIn delay={500}>
        <div className="feature-2-content">
          <div className="feature-2-content-title">
            {t('home.feature_date_invitations')}
          </div>
          <div className="feature-2-content-content">
            {t('home.feature_date_invitations_ps')}
          </div>
        </div>
        </FadeIn>

        <div className="feature-right-icon-div">
          <FadeIn delay={500}>
            <LottiePlayer
              lottie={"cake"}
              loop={3}
            />
          </FadeIn>
        </div>
      </div>
    )
  } else if(page === 3) {
    return (
      <div className="feature-2-content-outer fade-out-3">
        <div className="feature-2-content">
          <div className="feature-2-content-title">
            {t('home.feature_gift_game')}
          </div>
          <div className="feature-2-content-content">
            {t('home.feature_gift_game_ps')}
          </div>
        </div>

        <div className="feature-right-icon-div">
          <FadeIn delay={500}>
            <LottiePlayer
              lottie={"gift"}
              loop={3}
            />
          </FadeIn>
        </div>
      </div>
    )
  } else if(page === 4) {
    return (
      <div className="feature-2-content-outer fade-out-3">
        <div className="feature-2-content">
          <div className="feature-2-content-title">
            {t('home.feature_memorial_gallery')}
          </div>
          <div className="feature-2-content-content">
            {t('home.feature_memorial_gallery_ps')}
          </div>
        </div>

        <div className="feature-right-icon-div">
          <FadeIn delay={500}>
            <LottiePlayer
              lottie={"heart"}
              loop={3}
            />
          </FadeIn>
        </div>
      </div>
    )
  } else if(page === 5) {
    return (
      <div className="feature-2-content-outer fade-out-3">
        <div className="feature-2-content">
          <div className="feature-2-content-title">
            {t('home.feature_important_speech_to_you')}
          </div>
          <div className="feature-2-content-content">
            {t('home.feature_important_speech_to_you_ps')}
          </div>
        </div>

        <div className="feature-right-icon-div">
          <FadeIn delay={500}>
            <LottiePlayer
              lottie={"mail"}
              loop={3}
            />
          </FadeIn>
        </div>
      </div>
    )
  } else if(page === 6) {
    return (
      <div className="feature-2-content-outer fade-out-3">
        <div className="feature-2-content">
          <div className="feature-2-content-title">
            {t('home.feature_daily_schedule')}
          </div>
          <div className="feature-2-content-content">
            {t('home.feature_daily_schedule_ps')}
          </div>
        </div>

        <div className="feature-right-icon-div">
          <FadeIn delay={500}>
            <LottiePlayer
              lottie={"moon"}
              loop={3}
            />
          </FadeIn>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

function FeaturesBg({page, progress}) {
  const { t } = useTranslation()

  return (
    <div className="feature-bg-outer-div" style={{"--progress": progress}}>
      <div className="feature-bg-inner-div">
        <div className="feature-bg-left-row">
          <FadeIn className={"w-100 "+((page===6)?"fade-out-down-1":"")}>
          <a className="feature-bg-item" href="#2">
            <img src={date_invitations} className="feature-bg-item-img" alt=""/>
            <div className="feature-bg-item-text">
              {t('home.feature_date_invitations')}
            </div>
          </a>
          </FadeIn>
          <FadeIn delay={150} className={"w-100 "+((page===6)?"fade-out-down-1":"")}>
          <a className="feature-bg-item" href="#4">
            <img src={memorial_gallery} className="feature-bg-item-img" alt=""/>
            <div className="feature-bg-item-text">
              {t('home.feature_memorial_gallery')}
            </div>
          </a>
          </FadeIn>
          <FadeIn delay={300} className={"w-100 "+((page===6)?"fade-out-down-1":"")}>
          <a className="feature-bg-item" href="#6">
            <img src={daily_schedule} className="feature-bg-item-img" alt=""/>
            <div className="feature-bg-item-text">
              {t('home.feature_daily_schedule')}
            </div>
          </a>
          </FadeIn>
        </div>
        <div className="feature-bg-right-row">
          <FadeIn delay={450} className={"w-100 "+((page===6)?"fade-out-down-1":"")}>
          <a className="feature-bg-item" href="#3">
            <img src={gift_game} className="feature-bg-item-img" alt=""/>
            <div className="feature-bg-item-text">
              {t('home.feature_gift_game')}
            </div>
          </a>
          </FadeIn>
          <FadeIn delay={600} className={"w-100 "+((page==6)?"fade-out-down-1":"")}lassName="w-100">
          <a className="feature-bg-item" href="#5">
            <img src={important_speech_to_you} className="feature-bg-item-img" alt=""/>
            <div className="feature-bg-item-text">
              {t('home.feature_important_speech_to_you')}
            </div>
          </a>
          </FadeIn>
        </div>
      </div>
    </div>
  )
}

function PricePlan({page}) {
  const { t } = useTranslation()

  if(page === 7) {
    return (
      <div className="price-plan-div">
        <div className="price-plan-title">
          {t("home.price_plan_title")}
        </div>
        <div className="price-plan-plan-list">
          <FadeIn delay={250} className="price-plan-plan-list-item">
          <div className="price-plan-plan-list-item">
            <div className="price-plan-item price-plan-item-letter">
              <div className="price-plan-item-title price-plan-item-title-letter">
                GiftYou <div className="price-plan-item-title-letter-span">Letter</div>
              </div>
              <div className="price-plan-item-price price-plan-item-price-letter">
                {t('home.price_plan_free')}
              </div>
              <div className="price-plan-item-list">
                <FadeIn delay={550}>
                <div className="price-plan-item-list-div">
                  <FontAwesomeIcon icon={faCheck} className="price-plan-item-letter-check-div"/>
                  <div className="price-plan-item-list-div-text">
                    {t('home.feature_important_speech_to_you')}
                  </div>
                </div>
                </FadeIn>
              </div>
              <div className="price-plan-item-letter-btn noselect">
                {t('home.price_plan_free_trial')}
              </div>
            </div>
            <img src={blender_letter} className="price-plan-item-img-left" alt=""/>
          </div>
          </FadeIn>
          <FadeIn delay={500} className="price-plan-plan-list-item">
          <div className="price-plan-plan-list-item">
            <div className="price-plan-item price-plan-item-gift">
              <div className="price-plan-item-title price-plan-item-title-gift">
                GiftYou <div className="price-plan-item-title-gift-span">Gift</div>
              </div>
              <div className="price-plan-item-price">
                HKD 388
              </div>
              <div className="price-plan-item-list">
                <FadeIn delay={550}>
                <div className="price-plan-item-list-div">
                  <FontAwesomeIcon icon={faCheck} className="price-plan-item-gift-check-div"/>
                  <div className="price-plan-item-list-div-text">
                    {t('home.feature_important_speech_to_you')}
                  </div>
                </div>
                </FadeIn>
                <FadeIn delay={600}>
                <div className="price-plan-item-list-div mt-10px">
                  <FontAwesomeIcon icon={faCheck} className="price-plan-item-gift-check-div"/>
                  <div className="price-plan-item-list-div-text">
                    {t('home.feature_memorial_gallery')}
                  </div>
                </div>
                </FadeIn>
                <FadeIn delay={650}>
                <div className="price-plan-item-list-div mt-10px">
                  <FontAwesomeIcon icon={faCheck} className="price-plan-item-gift-check-div"/>
                  <div className="price-plan-item-list-div-text">
                    {t('home.feature_date_invitations')}
                  </div>
                </div>
                </FadeIn>
                <FadeIn delay={700}>
                <div className="price-plan-item-list-div mt-10px">
                  <FontAwesomeIcon icon={faCheck} className="price-plan-item-gift-check-div"/>
                  <div className="price-plan-item-list-div-text">
                    {t('home.feature_daily_schedule')}
                  </div>
                </div>
                </FadeIn>
                <FadeIn delay={750}>
                <div className="price-plan-item-list-div mt-10px">
                  <FontAwesomeIcon icon={faCheck} className="price-plan-item-gift-check-div"/>
                  <div className="price-plan-item-list-div-text">
                    {t('home.feature_gift_game')}
                  </div>
                </div>
                </FadeIn>
              </div>
            </div>
            <div className="feature-action-btn price-plan-item-letter-btn price-plan-item-gift-btn noselect">
              {t('home.price_plan_buy_it_now')}
            </div>
            <img src={blender_gift} className="price-plan-item-img-right" alt=""/>
          </div>
          </FadeIn>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

function Web({page, progress, startLottieIndex, endLottieIndex, toggleMakeItNow, initBackgroundAnimation, nextBackgroundAnimation}) {
  return (
    <div className={`inner-body`}>
      <div className="lottie-overlay-bg">
        <div className="lottie-bg">
          <Background startLottieIndex={startLottieIndex} endLottieIndex={endLottieIndex}/>
        </div>
      </div>

      {
        (page >= 2 && page < 7) && 
        <FeaturesBg page={page} progress={progress}/>
      }

      {/* <div className="debug">
        {page}, {pageIndex}, {pages}, {progress}
      </div> */}
      <div className={'box-div'}>
        <img src={heart} className="box-heart" alt=""/>
        <div className={'box-header'}>
          <div className="box-handle-div">
            <div className="box-handle-btn btn-red"></div>
            <div className="box-handle-btn btn-yellow"></div>
            <div className="box-handle-btn btn-green"></div>
          </div>
          <div className="box-nav-div">
            <FontAwesomeIcon icon={faCaretLeft} className="box-nav-btn"/>
            <FontAwesomeIcon icon={faCaretRight} className="box-nav-btn ml-20px"/>
          </div>
          <div className="box-search-div">
            <FontAwesomeIcon icon={faRedoAlt} className="box-reload-div"/>
          </div>
          <div className="box-nav-div">
            <FontAwesomeIcon icon={faStar} className="box-more-btn"/>
            <FontAwesomeIcon icon={faEllipsisV} className="box-more-btn ml-20px"/>
          </div>
        </div>

        <div className={"box-body"} >
          <BoxHeader />

          <ScrollToTop page={page} progress={progress}/>

          {/* Main */}
          <MainPage
            toggleMakeItNow={toggleMakeItNow}
            page={page}
            progress={progress}
          />

          {/* Features */}
          <Features
            initBackgroundAnimation={initBackgroundAnimation}
            nextBackgroundAnimation={nextBackgroundAnimation}
            page={page}
            progress={progress}
          />

          {/* PricePlan */}
          <PricePlan
            page={page}
          />

          {/* <div className="box-body-overlay"></div> */}
        </div>
      </div>
    </div>
  )
}
// ----------------------- Web -----------------------

// ----------------------- Mobile -----------------------
function BoxHeaderMobile({toggleMobileMenu}) {
  const { t } = useTranslation()
  return (
    <>
    <div className="box-body-header box-body-header-mobile">
      <a className="box-logo-a box-logo-a-mobile" href="/"><img src={logo} className="box-logo" alt=""/></a>
               
      <div className="box-body-header-btn box-body-header-btn-mobile">
        <FontAwesomeIcon icon={faBars} className="box-nav-btn box-nav-btn-mobile" 
          onClick={() => toggleMobileMenu()}
        />
      </div>
    </div>
    </>
  )
}

function MenuMobile({mobileIsOpenMenu, handleMobileMenuStateChange}) {
  const { t } = useTranslation()
  return (
    <Menu right
      outerContainerId="root"
      pageWrapId="mobile-div"  
      isOpen={mobileIsOpenMenu}
      onStateChange={handleMobileMenuStateChange}
    >
      <a id="home" className="menu-item" href="#2">{t('home.nav_features')}</a>
      <a id="about" className="menu-item" href="#7">{t('home.nav_price_plan')}</a>
      <div className="nav-btn nav-sign-btn nav-sign-btn-mobile noselect">
        {t('home.nav_sign_in')}
      </div>
    </Menu>
  )
}

function Chatbox({page, progress}) {
  const { t } = useTranslation()
  const [iconType, setIconType] = useState('boy')
  const [currentTextKey, setCurrentTextKey] = useState(0)
  const chat = {
    1: t('home.chat_welcome_1'),
    2: t('home.feature_date_invitations_ps_mobile'),
    3: t('home.feature_gift_game_ps_mobile'),
    4: t('home.feature_memorial_gallery_ps_mobile'),
    5: t('home.feature_important_speech_to_you_ps_mobile'),
    6: t('home.feature_daily_schedule_ps_mobile'),
    7: t('home.price_plan_free_ps'),
    8: t('home.price_plan_gift_ps')
  }

  useEffect(() => {
    setCurrentTextKey(page)
  }, [page])

  useEffect(() => {
    setIconType(Math.random() < 0.5 ?'girl' :'boy')
  }, [])

  return (
    <div className="chatbox-div-mobile">
      {
        iconType === 'boy' ?
        <img src={a_boy} className="chatbox-div-mobile-img" alt=""/>
        :
        <img src={a_girl} className="chatbox-div-mobile-img" alt=""/>
      }

      <div className="chatbox-text">
        <Typist 
          key={currentTextKey}
          avgTypingDelay={50}
          cursor={{show: false}}
        >
          {chat[page]}
          { page === 1 && <span className="chatbox-text-brand">Giftyou.</span> }
        </Typist>
      </div>
    </div>
  )
}

function MainPageMobile({ toggleMakeItNow, page, progress }) {
  const { t } = useTranslation()

  return (
    <div className="main-mobile-div" style={page === 1 ?{'--progress': progress} :{display: "none"}}>
      <div></div>

      <div>
        <FadeIn>
          <h1 className="main-div-title main-div-title-mobile fade-out-1">
          {t('home.main_titleline')} <b>{t('home.main_titleline_1')}</b> 
          <br/>
          {t('home.main_titleline_2')} <b>{t('home.main_titleline_3')}</b> ?</h1>
        </FadeIn>

        <FadeIn delay={300}>
          <h2 className="main-div-content main-div-content-mobile fade-out-2">
            {t('home.main_content_mobile')}
          </h2>
        </FadeIn>
      </div>

      <div className="main-mobile-main-btn">
        <FadeIn delay={600}>
          <div className={"main-action-btn main-action-btn-mobile noselect fade-out-3 " + ((progress > 0.01) ?"main-action-btn-disabled-hover" :"")} onClick={toggleMakeItNow} style={progress > 0.01 ?{transition: '0s'} :{}}>
            {t('home.main_make_it_now')}
          </div>
        </FadeIn>
      </div>
    </div>
  )
}

function FeaturesMobile({ initBackgroundAnimation, nextBackgroundAnimation, page, progress }) {
  const [innerPage, setInnerPage] = useState(null);
  const { t } = useTranslation()
  var list = [
    {
      lang: t('home.feature_date_invitations'),
      img: date_invitations
    },
    {
      lang: t('home.feature_gift_game'),
      img: gift_game
    },
    {
      lang: t('home.feature_memorial_gallery'),
      img: memorial_gallery
    },
    {
      lang: t('home.feature_important_speech_to_you'),
      img: important_speech_to_you
    },
    {
      lang: t('home.feature_daily_schedule'),
      img: daily_schedule
    },
  ]

  useEffect(() => {
    if(page >= 2) {
      nextBackgroundAnimation();
    } else if(innerPage === 2 && page === 1) {
      initBackgroundAnimation();
    }
    if(innerPage !== page) {
      setInnerPage(page)
    }
  }, [page])


  if(page >= 2 && page < 7) {
    return (
      <div className="feature-outer-div feature-outer-div-mobile" style={{"--progress": progress}}>
        <h1 className="feature-mobile-div-title">{t('home.feature_feature')}</h1>
        <div className="feature-mobile-content-div">
          <div className="feature-mobile-content-div-row">
            <div className="feature-mobile-left-div">
              <FadeIn>
                <div className="feature-phone-div">
                  <img src={phone} className="feature-phone-img feature-phone-img-mobile" alt=""/>
                  <FeaturesDemoMobile page={page} progress={progress} />
                </div>
              </FadeIn>
            </div>
            <div className="feature-mobile-right-div">
              {
                list.map((item, index) => (
                  <a key={index} className="feature-mobile-list-item" href={"#"+(index+2)}>
                    <span className={"feature-mobile-list-item-span" + (page === index+2 ?" feature-mobile-list-item-span-active" :"")}>{item.lang}</span>
                    <img src={item.img} className={"feature-mobile-list-item-icon" + (page === index+2 ?" feature-mobile-list-item-icon-active" :"")} alt=""/>
                  </a>
                ))
              }
            </div>
          </div>
        </div>

        <FadeIn>
            <div className="feature-action-button-div">
              {/* <div className="feature-demo-btn feature-demo-btn-mobile noselect">
                {t("home.feature_demo")}
              </div> */}
              <div className="feature-action-btn feature-demo-btn-mobile noselect">
                {t("home.feature_try_it_now")}
              </div>
            </div>
          </FadeIn>
      </div>
    )
  } else {
    return <></>
  }
}

function FeaturesDemoMobile({page, progress}) {
  {/* Date Invitation */}
  if(page === 2) {
    return (
      <>
      <div className="feature-2-img-div feature-2-img-div-mobile">
        <FadeIn delay={600}>
        <img src={date_invitation_up} className="feature-2-img-up feature-phone-up-fade-out" alt=""/>
        </FadeIn>
        <FadeIn delay={800}>
        <img src={date_invitation_down} className="feature-2-img-down feature-phone-down-fade-out" alt=""/>
        </FadeIn>
      </div>
      {
        progress > 0.51 && 
        <div className="feature-2-img-main-div feature-2-img-div-mobile">
          <img src={demo_main} className="feature-2-img-up  feature-main-fade"/>
          <img src={demo_chat} className="feature-2-img-down feature-main-chat-fade"/>
        </div>
      }
      </>
    )
  } else if(page === 3) {
    return (
      <>
      <div className="feature-2-img-div feature-2-img-div-mobile"  style={{"--progress": progress}}>
        {
          (progress < 1) &&
          <>
          <img src={demo_back} className="feature-3-img-back feature-3-img-back-mobile feature-main-fade feature-3-fade-out-2-up" alt=""/>
          <br/>
          </>
        }
        {
          progress < 0.5 &&
          <img src={demo_gift_card} className="feature-3-img-content feature-3-img-content-margin feature-main-chat-fade feature-3-fade-out-1" alt=""/>
        }
        {
          (progress > 0.5 && progress < 0.99) &&
          <img src={demo_gift_question} className="feature-3-img-content feature-3-img-content-margin feature-main-chat-fade feature-3-fade-out-2" alt=""/>
        }
      </div>
      </>
    )
  } else if(page === 4) {
    return (
      <div className="feature-2-img-div feature-2-img-div-mobile">
        <img src={demo_back} className="feature-3-img-back feature-3-img-back-mobile feature-main-fade feature-3-fade-out-2-up" alt=""/>
        <br/>
        {
          progress < 0.95 &&
          <img src={demo_memory} className="feature-4-img-content feature-main-chat-fade feature-3-fade-out-2" alt=""/>
        }
      </div>
    )
  } else if(page === 5) {
    return (
      <div className="feature-2-img-div feature-2-img-div-mobile">
        <img src={demo_back} className="feature-3-img-back feature-3-img-back-mobile feature-main-fade feature-3-fade-out-2-up" alt=""/>
        <br/>
        {
          progress < 0.95 &&
          <img src={demo_letter} className="feature-5-img-content feature-5-img-content-mobile feature-main-chat-fade feature-3-fade-out-2" alt=""/>
        }
      </div>
    )
  } else if(page === 6) {
    return (
      <div className="feature-2-img-div feature-2-img-div-mobile">
        <img src={demo_back} className="feature-3-img-back feature-3-img-back-mobile feature-main-fade feature-3-fade-out-2-up" alt=""/>
        <br/>
        {
          progress < 0.95 &&
          <img src={demo_schedule} className="feature-3-img-content feature-main-chat-fade feature-3-fade-out-2" alt=""/>
        }
      </div>
    )
  } else {
    return <></>
  }
}

function PricePlanMobile({page, progress}) {
  const { t } = useTranslation()

  if(page >= 7) {
    return (
      <div className="feature-outer-div feature-outer-div-mobile" style={{"--progress": progress}}>
        <h1 className="feature-mobile-div-title">{t('home.price_plan_title')}</h1>
        <div className="feature-mobile-price-plan-div-mobile">
          {
            (page === 7) ?
            <div className="price-plan-plan-list-item">
              <div className="price-plan-item price-plan-item-mobile price-plan-item-letter">
                <div className="price-plan-item-title price-plan-item-title-mobile price-plan-item-title-letter">
                  GiftYou <div className="price-plan-item-title-letter-span">Letter</div>
                </div>
                <div className="price-plan-item-price price-plan-item-price-mobile price-plan-item-price-letter">
                  {t('home.price_plan_free')}
                </div>
                <div className="price-plan-item-list price-plan-item-list-mobile">
                  <img src={blender_letter} className="price-plan-item-img-left-mobile" alt=""/>
                  <FadeIn delay={250}>
                  <div className="price-plan-item-list-div">
                    <FontAwesomeIcon icon={faCheck} className="price-plan-item-letter-check-div"/>
                    <div className="price-plan-item-list-div-text price-plan-item-list-div-text-mobile">
                      {t('home.feature_important_speech_to_you')}
                    </div>
                  </div>
                  </FadeIn>
                </div>
                <div className="price-plan-item-letter-btn price-plan-item-letter-btn-mobile noselect">
                  {t('home.price_plan_free_trial')}
                </div>
              </div>
            </div>
            :((page === 8)) &&
            <div className="price-plan-plan-list-item">
              <div className="price-plan-item price-plan-item-mobile price-plan-item-gift">
                <div className="price-plan-item-title price-plan-item-title-mobile price-plan-item-title-gift">
                  GiftYou <div className="price-plan-item-title-gift-span">Gift</div>
                </div>
                <div className="price-plan-item-price price-plan-item-price-mobile price-plan-item-price-letter">
                  HKD 388
                </div>
                <div className="price-plan-item-list price-plan-item-list-mobile">
                  <img src={blender_gift} className="price-plan-item-img-right-mobile" alt=""/>
                  <FadeIn delay={250}>
                  <div className="price-plan-item-list-div">
                    <FontAwesomeIcon icon={faCheck} className="price-plan-item-gift-check-div"/>
                    <div className="price-plan-item-list-div-text price-plan-item-list-div-text-mobile">
                      {t('home.feature_important_speech_to_you')}
                    </div>
                  </div>
                  </FadeIn>
                  <FadeIn delay={300}>
                  <div className="price-plan-item-list-div mt-10px">
                    <FontAwesomeIcon icon={faCheck} className="price-plan-item-gift-check-div"/>
                    <div className="price-plan-item-list-div-text price-plan-item-list-div-text-mobile">
                      {t('home.feature_memorial_gallery')}
                    </div>
                  </div>
                  </FadeIn>
                  <FadeIn delay={350}>
                  <div className="price-plan-item-list-div mt-10px">
                    <FontAwesomeIcon icon={faCheck} className="price-plan-item-gift-check-div"/>
                    <div className="price-plan-item-list-div-text price-plan-item-list-div-text-mobile">
                      {t('home.feature_date_invitations')}
                    </div>
                  </div>
                  </FadeIn>
                  <FadeIn delay={400}>
                  <div className="price-plan-item-list-div mt-10px">
                    <FontAwesomeIcon icon={faCheck} className="price-plan-item-gift-check-div"/>
                    <div className="price-plan-item-list-div-text price-plan-item-list-div-text-mobile">
                      {t('home.feature_daily_schedule')}
                    </div>
                  </div>
                  </FadeIn>
                  <FadeIn delay={450}>
                  <div className="price-plan-item-list-div mt-10px">
                    <FontAwesomeIcon icon={faCheck} className="price-plan-item-gift-check-div"/>
                    <div className="price-plan-item-list-div-text price-plan-item-list-div-text-mobile">
                      {t('home.feature_gift_game')}
                    </div>
                  </div>
                  </FadeIn>
                </div>
                <div className="feature-action-btn price-plan-item-letter-btn price-plan-item-letter-btn-mobile noselect">
                  {t('home.price_plan_buy_it_now')}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  } else {
    return <></>
  }
}


function Mobile({page, progress, startLottieIndex, endLottieIndex, toggleMakeItNow, initBackgroundAnimation, nextBackgroundAnimation, toggleMobileMenu}) {
  useEffect(() => {
    if(document.body.style.overflowY === 'hidden') {
      document.body.style.overflowY = "scroll"
    }
    window.scrollTo(0, 1);
  }, [])

  return (
    <div id="mobile-div" className={`inner-body inner-body-mobile`}>
      <div className={'box-div box-div-mobile'}>
        <img src={heart} className="box-heart box-heart-mobile" alt=""/>
        <div className={'box-header box-header-mobile'}>
          <div className="box-search-div box-search-div-mobile">
          </div>
          <div className="box-nav-div">
            <FontAwesomeIcon icon={faStar} className="box-more-btn"/>
            <FontAwesomeIcon icon={faEllipsisV} className="box-more-btn ml-20px"/>
          </div>
        </div>

        <div className="box-mobile-bg-div">
          <img src={blender_gift} className="box-mobile-bg" alt=""/>
        </div>

        <div className={"box-body box-body-mobile"} >
          <BoxHeaderMobile toggleMobileMenu={toggleMobileMenu} />

          <MainPageMobile
            toggleMakeItNow={toggleMakeItNow}
            page={page}
            progress={progress}
          />

          <FeaturesMobile
            initBackgroundAnimation={initBackgroundAnimation}
            nextBackgroundAnimation={nextBackgroundAnimation}
            page={page}
            progress={progress}
          />

          <PricePlanMobile
            page={page}
            progress={progress}
          />
        </div>
      </div>

     <Chatbox page={page} progress={progress}/>
    </div>
  )
}
// ----------------------- Mobile -----------------------

class HomeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.connectSyncAPI = null;
    this.state = {
      startLottieIndex: 0,
      endLottieIndex: 34,
      windowSize: getWindowSize(),
      mobileIsOpenMenu: false
    }
  }

  componentDidMount() {
    disableScroll(3);

    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  toggleMakeItNow = () => {
    
  }

  handleWindowResize = () => {
    this.setState({windowSize: getWindowSize()})
  }

  initBackgroundAnimation = () => {
    this.setState({
      startLottieIndex: 0,
      endLottieIndex: 34
    })
  }

  nextBackgroundAnimation = () => {
    if(this.state.startLottieIndex == 0) {
      this.setState({
        startLottieIndex: 34,
        endLottieIndex: 70
      })
    }
  }

  toggleMobileMenu = () => {
    this.setState({mobileIsOpenMenu: !this.state.mobileIsOpenMenu})
  }
  handleMobileMenuStateChange = (state) => {
    this.setState({mobileIsOpenMenu: state.isOpen})
  }

  // ------------------------- View -------------------------
  render() {
    return (
      <>
        <SyncAPI onRef={(ref) => this.connectSyncAPI = ref} />
        
        <Stickyroll
          pages={sections[this.state.windowSize.innerWidth >= 1000 ? 'web' : 'mobile']}
          factor={1}
          anchors="">
          {({page, progress}) => (
            <>
            <div className="progress-bar-div" style={{"--progress": progress}}></div>
            {
              this.state.windowSize.innerWidth >= 1000 ?
              <Web 
                page={page} 
                progress={progress}
                startLottieIndex={this.state.startLottieIndex}
                endLottieIndex={this.state.endLottieIndex}
                toggleMakeItNow={this.toggleMakeItNow}
                initBackgroundAnimation={this.initBackgroundAnimation}
                nextBackgroundAnimation={this.nextBackgroundAnimation}
              />
              :
              <>
              <MenuMobile mobileIsOpenMenu={this.state.mobileIsOpenMenu} toggleMobileMenu={this.toggleMobileMenu} handleMobileMenuStateChange={this.handleMobileMenuStateChange}/>
              <Mobile 
                page={page} 
                progress={progress}
                startLottieIndex={this.state.startLottieIndex}
                endLottieIndex={this.state.endLottieIndex}
                toggleMakeItNow={this.toggleMakeItNow}
                initBackgroundAnimation={this.initBackgroundAnimation}
                nextBackgroundAnimation={this.nextBackgroundAnimation}
                toggleMobileMenu={this.toggleMobileMenu}
              />
              </>
            }
            </>
          )}
        </Stickyroll>
      </>
    )
  }
  // ------------------------- View -------------------------
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);