import { reactotronRedux } from 'reactotron-redux'
import Reactotron from 'reactotron-react-js'

const reactotron = Reactotron
    .configure({ 
        name: 'aspecial.gift',
        // host: '192.168.1.6' 
        host: '192.168.128.79'
    })
    .use(reactotronRedux()) 
    // .connect() 

export default reactotron