import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import langEn from './locale/en.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: langEn
    },
    lng: "en",
    fallbackLng: "en",

    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    }
  });

  export default i18n;