import URL from "../api.json";
import axios from "axios";

const GlobalAPIConfig = {
  headers: {
      Accept: 'application/json',
      "Access-Control-Allow-Origin": "*",
      'Access-Control-Allow-Credentials': true,
      'Content-Type': 'application/json',
  },
  responseType: 'json'
}
// const GlobalFormAPIConfig = {
//   headers: {
//       Accept: 'application/json',
//       'Content-Type': 'multipart/form-data'
//   },
//   responseType: 'json'
// }

// ====================== Passcode ======================
export const checkPasscode = (params) => {
  let tempApiConfig = { ...GlobalAPIConfig };

  return axios.post(`${URL.BACKEND_URL}/api/app/verifyCode`, JSON.stringify(params), tempApiConfig)
    .then((res) => {
      return res
    })
    .catch(function (error) {
      console.log(error);
    });
}

// ====================== Unlock ======================
export const getImageList = (params) => {
  let tempApiConfig = { ...GlobalAPIConfig };

  return axios.post(`${URL.BACKEND_URL}/api/app/getImageList`, JSON.stringify(params), tempApiConfig)
    .then((res) => {
      return res
    })
    .catch(function (error) {
      console.log(error);
    });
}

