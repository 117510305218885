import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { toast } from 'react-toastify';

import * as COMMON from '../../redux/constants/Common';
import { isNotNull } from '../../utils/commonFunction'
import * as CommonActions from '../../redux/actions/CommonActions';


const displayToastMessageEpic = (action$) => action$.pipe(
  ofType(COMMON.DISPLAY_TOAST_MESSAGE),
  mergeMap((action) => {
    const { type, message } = action.payload.data;

    if (isNotNull(message) && type === 'success') {
      toast.success(message, {
        position: "bottom-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(message, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return of(CommonActions.templateCancelledAction());
  }),
);


// const tempEpic = (action$, state$) => action$.pipe(
//     ofType(COMMON.LOGIN),
//     withLatestFrom(state$),
//     mergeMap(([action, state]) => {
//         const { memberCardPage } = state.member
//         const { countryCode, phoneNumber } = state.member.memberInfo
//         const { locale } = state.common;
//         const params = {
//             phoneNumber: countryCode + phoneNumber,
//             lang: locale || Config.DEFAULT_LANGUAGE,
//             // page: memberCardPage
//         }

//         return MemberService.getAllMemberCardByPhone(params).pipe(
//             map((res) => {
//                 if (!res.data.result) {
//                     return MemberActions.getAllMemberCardByPhoneRejected('No result found');
//                 }
//                 return MemberActions.getAllMemberCardByPhoneFulfilled(res.data);
//             }),
//             catchError((error) => of(MemberActions.getAllMemberCardByPhoneRejected(error ? error : I18n.t(['ERRORS', 'NETWORK-ERROR'])))),
//             takeUntil(action$.pipe(ofType(MEMBER.GET_ALL_MEMBER_CARD_CANCELLED))),
//         );
//     })
// )

export default [
  displayToastMessageEpic
]