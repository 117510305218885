import { createAction } from "typesafe-actions";
import * as COMMON from "../constants/Common";


// Initial State
export const setInitialState = createAction(
  COMMON.INITIAL_STATE,
  (resolve) => (initialState) => resolve({ data: { initialState } })
)

// Change Theme
export const changeThemeAction = createAction(
  COMMON.CHANGE_THEME,
  (resolve) => (theme) => resolve({ data: { theme } })
)

// Display toast
export const displayToastMessage = createAction(
  COMMON.DISPLAY_TOAST_MESSAGE,
  (resolve) => (type, message) => resolve({ data: { type, message }})
)

// Template
export const template = createAction(
  COMMON.TEMPLATE,
  (resolve) => (v) => resolve({ data: { v }})
)
export const templateFulfilledAction = createAction(
  COMMON.TEMPLATE_FULFILLED,
  (resolve) => (res) => resolve({ data: { res } })
)
export const templateRejectedAction = createAction(
  COMMON.TEMPLATE_REJECTED,
  (resolve) => (error) => resolve({ error })
)
export const templateCancelledAction = createAction(
  COMMON.TEMPLATE_CANCELED,
  (resolve) => () => resolve()
)
