import moment from "moment";

export const isFunction = (x) => {
    return Object.prototype.toString.call(x) === '[object Function]';
}

// Detect the variable existence and empty value
export const isNotNull = (value) => {
    if (value !== undefined && value !== null && value) {
        return true;
    }
    return false;
}

export const isBetweenDate = (start, end) => {
    const currentTimestamp = moment().unix();
    const validStartTimestamp = moment(start).unix();
    const validEndTimestamp = moment(end).unix();
    if (+currentTimestamp < +validStartTimestamp || +currentTimestamp > +validEndTimestamp) {
        return false;
    }
    return true;
}

export const replaceQuotation = (string) => {
    return ((string.replace(/"/gi, "'")).replace(/”/gi, "'")).replace(/“/gi, "'");
}

// Number with comma
export const amountWithComma = (x, isHide = null) => {
    if(isHide) return '*****'
    if (!x) return 0;
    if(isNaN(x)) return 0;
    // if(x >= 1000000000000) {
    //     return parseFloat((x / 1000000000000)).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'T';
    // } else if(x >= 1000000000) {
    //     return parseFloat((x / 1000000000)).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'B';
    // } else if(x >= 1000000) {
    //     return parseFloat((x / 1000000)).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'M';
    // } else if(x >= 1000) {
    //     return parseFloat((x / 1000)).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'K';
    // } else {
    //     if(parseInt(x) === x) {
    //         return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //     } else {
    //         x = parseFloat(x).toFixed(2)
    //         return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //     }
    // }

    // if(parseInt(x) === x) {
    //     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // } else {
    //     x = parseFloat(x).toFixed(2)
    //     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
    x = parseFloat(x).toFixed(2)
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const convertArrayToGetParam = (params) => {
    let getParam = '?';
    let index = 1;
    for(let key in params) {
        if(typeof params[key] !== "string" && typeof params[key] !== 'number') {
            getParam += `${key}=${encodeURIComponent(JSON.stringify(params[key]))}`
        } else {
            getParam += `${key}=${params[key]}`
        }
        if(index < Object.keys(params).length) {
            getParam += `&`
            index++;
        }
    }

    return getParam;
}

export const encryptAES = (input) => {
  const nacl = require('tweetnacl')
  const utils = require('tweetnacl-util')
  const encodeBase64 = utils.encodeBase64
  // Our nonce must be a 24 bytes Buffer (or Uint8Array)
  const nonce = nacl.randomBytes(24)
  // Our secret key must be a 32 bytes Buffer (or Uint8Array)
  const secretKey = Buffer.from('fLJvUFAiXZZXRzdqLUxIcgxzRLkseyXj', 'utf8')
  // Make sure your data is also a Buffer of Uint8Array
  const secretData = Buffer.from(input, 'utf8')
  const encrypted = nacl.secretbox(secretData, nonce, secretKey)
  // We can now store our encrypted result and our nonce somewhere
  const result = `${encodeBase64(nonce)}:${encodeBase64(encrypted)}`

  return result;
}

export const disableScroll = (type = null) => {
    document.body.style.overflowY = "hidden"
}

export const enableScroll = (timeout = null) => {
    if(document.body.style.overflowY === 'hidden') {
        document.body.style.overflowY = "inherit"
    }
}

export const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight}
}