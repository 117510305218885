export const lightTheme = {
  body: '#fafafa',
  cardBody: '#eee',
  cardBodyHover: '#ddd',
  cardBodyActive: '#ccc',
  text: '#333',
  cardText: '#333',
  toggleBorder: '#FFF',
  background: '#363537',
}
export const darkTheme = {
  body: '#333',
  cardBody: '#222',
  cardBodyHover: '#111',
  cardBodyActive: '#000',
  text: '#FAFAFA',
  cardText: '#ddd',
  toggleBorder: '#6B8096',
  background: '#999',
}