import * as COMMON from '../constants/Common';

const initialState = {
    initialState: false,
    theme: 'light'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case COMMON.INITIAL_STATE: {
      const { initialState } = action.payload.data;
      return {
        ...state,
        initialState: initialState,
      };
    }

    // Change Theme
    case COMMON.CHANGE_THEME: {
      let { theme } = action.payload.data;
      if(theme === undefined || theme === null || theme !== 'dark') {
        theme = 'light'
      }
      return {
        ...state,
        theme,
      };
    }

    // Display toast
    case COMMON.DISPLAY_TOAST_MESSAGE: {
      return {
        ...state
      }
    }

    // Template
    case COMMON.TEMPLATE: {
      return {
        ...state,
        isLoading: true
      }
    }
    case COMMON.TEMPLATE_FULFILLED: {
      if (action.payload.data) {

        return {
          ...state,
          isLoading: false
        }
      }
      break;
    }
    case COMMON.TEMPLATE_REJECTED: {
      return {
        ...state,
        isLoading: false
      }
    }
    case COMMON.TEMPLATE_CANCELED: {
      return {
        ...state,
        isLoading: false
      }
    }

    default: {
      return state;
    }
  }
}
