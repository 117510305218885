import Lottie from 'react-lottie-player/dist/LottiePlayerLight'
import React, { useEffect, useState } from "react";

function LottiePlayer({lottie, loop = false, speed = 1}) {
  const [animationData, setAnimationData] = useState();
  
  useEffect(() => {
    if(lottie === 'cake') {
      import('../images/lottie/cake.json').then(setAnimationData)
    } else if(lottie === 'gift') {
      import('../images/lottie/gifts.json').then(setAnimationData)
    } else if(lottie === 'heart') {
      import('../images/lottie/heart.json').then(setAnimationData)
    } else if(lottie === 'mail') {
      import('../images/lottie/mail.json').then(setAnimationData)
    } else if(lottie === 'moon') {
      import('../images/lottie/moon.json').then(setAnimationData)
    }
  }, [lottie])

  if (!animationData) return <div></div>
  return  <Lottie animationData={animationData} 
            play
            loop={loop}
            speed={speed}
          />
}

export default LottiePlayer;