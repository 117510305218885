import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'
import Reactotron from 'reactotron-react-js'
import _ from "lodash";

import './css/index.css';
import Main from './views'
import configureStore from "./redux/store";
// import reportWebVitals from './reportWebVitals';

const { store, persistor } = configureStore();

global._ = _;
global.log = (input) => {
  Reactotron.log(input);
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <Main />
      </I18nextProvider>
    </PersistGate>
  </Provider>
  , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
