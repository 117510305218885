import React from 'react';
import { connect } from 'react-redux';

const APIService = require('./services/APIService');
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.click = false;
    if (this.props.onRef !== undefined) { this.props.onRef(this); }
    this.state = {}
  }

  // ======================== Unlock ========================
  getImageList = (params) => {
    return APIService.getImageList(params).then((res) => res);
  }

  render() {
    return (
      <div></div>
    )
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch, props) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
