import { createGlobalStyle} from "styled-components"
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 0.50s linear;
    font-family: 'JetBrains Mono', monospace;
  }

  .card {
    display: table !important;
    background: ${({ theme }) => theme.cardBody};
    color: ${({ theme }) => theme.cardText};
    border-radius: 15px;
    padding: 22px;
    cursor: pointer;
    margin-right: 20px;
    min-width: 200px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-decoration: none !important;
    border: 0px !important;
    margin-top: 5px;
  }

  .card:hover {
    background: ${({ theme }) => theme.cardBodyHover};
  }

  .card:active {
    background: ${({ theme }) => theme.cardBodyActive};
  }
`
